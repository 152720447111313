
export async function get(url, path='', token = '', queryParams = {}, headers = {}) {
  return await fetchAPI(url, path, "GET", null, token, queryParams && {}, headers);
}

export async function GET(url, { path, token, queryParams, headers, isJson } = { path:'', token : '', queryParams : {}, headers : {}, isJson: true }) {
  return await fetchAPI(url, path, "GET", null, token, queryParams && {}, headers, isJson);
}

export async function post(url, path='', body, token = '', queryParams = {}, headers = {}) {
  return await fetchAPI(url, path, "POST", body, token, queryParams && {}, headers);  
}

export async function remove(url, path='', body, token = '', queryParams = {}, headers = {}) {
  return await fetchAPI(url, path, "DELETE", body, token, queryParams && {}, headers);  
}

export async function patch(url, path='', body, token = '', queryParams = {}, headers = {}) {
  return await fetchAPI(url, path, "PATCH", body, token, queryParams && {}, headers);  
}

async function fetchAPI(url, path='', method, body = null, token = '', queryParams = {}, headers = {}, isJson=true) {
  headers = { "Content-Type": "application/json", ...headers};
  if(token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const requestData = {
    headers,
    method: method,
  };
  if(method !== "GET" && (body === 0 || body)) {
    requestData.body = JSON.stringify(body);
  }
  const res = await fetch(url + path, requestData);

  let response = null;
  if(isJson) {
    response = await res.json();
    if (response?.errors) {
      console.error(response.errors);
      // throw new Error("Failed to fetch API");
    }
  } else {    
    response = await res.text();
  }
  return response;
}