import React from "react";
import BrokerAccounts from '../components/brokerAccounts/BrokerAccount';


export default function IntegrationsComponent() {
  return (
    <BrokerAccounts />
  );
};

