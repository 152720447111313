import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'
import { Button, Card, CardHeader, CardBody, Col, Container, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Label } from "reactstrap";
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { MdDeleteForever } from 'react-icons/md';
import { FaPause, FaPlay } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import { post, remove } from '../../lib/api';

import { toggleLinkAccount, linkAccount } from '../../components/subscriptions/subscriptionsSlice';
import { BrokerAccountHeadView } from "../brokerAccounts/functionalComponents";
import { initializeBrokerAccounts, getAccountById } from "../brokerAccounts/service";
import subscriptionsService, { initializeSubscriptions } from "./service";
import { subscriptionSchema } from './subscriptionSchema';

const API_URL = process.env.REACT_APP_API_SERVER_URL;


export default function Subscriptions() {
    const { getAccessTokenSilently } = useAuth0();

    // Fetch resource on page load.
    useEffect( () => {
        initializeSubscriptions(getAccessTokenSilently);
    }, []);

    return (
        <>
            {/* Heading */}
            <Head />

            {/* List & Edit & Delete */}
            <EditableList />

            {/* Create */}
            <CreateNew />
        </>
    );
}

function Head() {
    return (
        <h2>Subscriptions</h2>
    );
}

function EditableList() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const subscriptions = useSelector((state) => state.subscriptions.data);
    const brokerAccounts = useSelector((state) => state.brokerAccounts);

    useEffect(() => {
        initializeSubscriptions(getAccessTokenSilently);
        initializeBrokerAccounts(getAccessTokenSilently);
    }, []);

    const handleRemoveSubscription = useCallback(async (index) => {
        const accessToken = await getAccessTokenSilently();
        const body = { _id: subscriptions[index]['_id']['$oid'] };
        let response = await remove(API_URL, '/subscriptions', body, accessToken);

        initializeSubscriptions(getAccessTokenSilently, true);
    }, [subscriptions]);

    const handleToggleSubscription = useCallback(async (index) => {
        await subscriptionsService.toggleSubbscription(getAccessTokenSilently, subscriptions[index]);
        await subscriptionsService.initializeSubscriptions(getAccessTokenSilently, true);
    }, [subscriptions]);

    return (
        <>
            {subscriptions?.length > 0 && subscriptions.map((subscription, index) =>
                <>
                    { subscription?._fe_linkAccount && (
                        <LinkAccountModal 
                            subscription={subscription}
                            index={index}
                            key={`link-account-${index}`} 
                        /> 
                    )}

                    <Card
                        className="my-2"
                        style={{
                            width: '100%'
                        }}
                        key={`subscription-${index}`}
                    >
                        <CardHeader>
                            <Container>
                                <Row>
                                    <Col>
                                        {subscription.productType}
                                        <span className="float-end">
                                            <Button color="secondary" onClick={() => dispatch(linkAccount(index))}> <FaLink /> </Button>
                                            {' '}
                                            <Button color="secondary" onClick={() => handleToggleSubscription(index)}> { subscription?.active ? <FaPause /> : <FaPlay /> } </Button>
                                            {' '}   
                                            <Button color="danger" onClick={() => handleRemoveSubscription(index)}> <MdDeleteForever /> </Button>
                                        </span>
                                    </Col>
                                </Row>
                            </Container>
                        </CardHeader>
                        <CardBody>
                            <Container>
                                <Row>
                                    <Col>
                                        <Row>
                                            Script Type: {subscription.scriptType}
                                        </Row>
                                        <Row>
                                            Script: {subscription.script}
                                        </Row>
                                        <Row>
                                            Duration: {subscription.duration}
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                            { (subscription.brokerAccountId?.$oid && brokerAccounts?.initialized) ? (
                                                <>
                                                    <BrokerAccountHeadView account={ getAccountById( brokerAccounts?.data, subscription.brokerAccountId?.$oid ) } />
                                                    <Button className="float-end" outline onClick={() => dispatch(linkAccount(index))}>{subscription.brokerAccountId?.$oid ? 'Update' : 'Link Account'}</Button>
                                                </>
                                            ) : null }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Batch Investment Amount: {subscription?.batchInvestmentAmount}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Per-script Limit Amount: {subscription?.perScriptAmountLimit}
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Container>
                        </CardBody>
                    </Card>
                </>
            )}
        </>
    );
}

function CreateNew() {
    const { getAccessTokenSilently } = useAuth0();
    const [showForm, setShowForm] = useState(false);

    const handleSubmit = useCallback(async ({ formData }) => {
        const accessToken = await getAccessTokenSilently();
        let response = await post(API_URL, '/subscriptions', formData, accessToken);
        console.log('Create new subscription response: ', response);

        // Hide form
        setShowForm(false);

        initializeSubscriptions(getAccessTokenSilently, true);
   }, []);

    return (
        <>
            {showForm && (
                <Form 
                    schema={subscriptionSchema}
                    validator={validator}
                    onSubmit={handleSubmit}
                >
                    <div className="mt-2">
                        <Button color="primary" type="submit">Submit</Button>
                        {' '}
                        <Button color="secondary" outline onClick={ () => setShowForm(false) }>Cancel</Button>
                    </div>
                </Form>
            )}
            {!showForm && (
                <Button color="primary" onClick={ () => setShowForm(true) }>Add New</Button>
            )}
        </>
    );
}


function LinkAccountModal({ subscription, index }) {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const accounts = useSelector((state) => state.brokerAccounts.data);
    const [batchInvestmentAmount, setBatchInvestmentAmount] = useState(0);
    const [perScriptAmountLimit, setPerScriptAmountLimit] = useState(0);
    const [selectedAccountIndex, setSelectedAccountIndex] = useState(null);

    const closeModal = useCallback(() => dispatch(toggleLinkAccount(index)), [index]);

    useEffect(() => {
        setBatchInvestmentAmount(subscription?.batchInvestmentAmount);
        setPerScriptAmountLimit(subscription?.perScriptAmountLimit);
    }, [subscription?.batchInvestmentAmount, subscription?.perScriptAmountLimit]);

    const handleLinkAccount = useCallback(async () => {
        const index = parseInt(selectedAccountIndex);
        const account = index === -1 ? null : accounts[index];
        await subscriptionsService.linkAccount(getAccessTokenSilently, subscription, account, batchInvestmentAmount, perScriptAmountLimit);

        closeModal();

        await subscriptionsService.initializeSubscriptions(getAccessTokenSilently, true);
    }, [subscription, accounts, selectedAccountIndex]);

    return (
        <Modal isOpen={true}>
            <ModalHeader toggle={closeModal}>Link Account</ModalHeader>
            <ModalBody>
                {accounts.length > 0 ? (
                    <>
                        <Row className="mb-2" key={`account-id-${-1}`}>
                            <Col md="1">
                                <Input type="radio" value={-1} id={`account-id-${-1}`} name="account" onClick={() => setSelectedAccountIndex(-1)} />
                            </Col>
                            <Col>
                                <Label for={`account-id-${-1}`}>
                                    None
                                </Label>
                            </Col>

                        </Row>

                        {accounts.map((account, index) => (
                            <Row className="mb-2" key={`account-id-${index}`}>
                                <Col md="1">
                                    <Input type="radio" value={index} id={`account-id-${index}`} name="account" onClick={() => setSelectedAccountIndex(index)} />
                                </Col>
                                <Col>
                                    <Label for={`account-id-${index}`}>
                                        <BrokerAccountHeadView account={account} />
                                    </Label>
                                </Col>
                            </Row>
                        ))}

                        <Row>
                            <Col>
                                Batch Investment Amount: <Input type="number"  value={batchInvestmentAmount} onChange={({target}) => setBatchInvestmentAmount(target.value)}></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Per-script Limit Amount: <Input type="number"  value={perScriptAmountLimit} onChange={({target}) => setPerScriptAmountLimit(target.value)}></Input>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <div>No accounts found yet, please <a href="/integrations" target="_blank">integrate</a> your broker accounts.</div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleLinkAccount} disabled={selectedAccountIndex === null}>
                    Link
                </Button>
                {' '}
                <Button color="secondary" onClick={closeModal}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}