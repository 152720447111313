import { useEffect, useState } from "react";
import researchService from './service';
import { useAuth0 } from '@auth0/auth0-react';
import Percentile from "./functionalComponents/Percentile";
import MarketMovers from "./functionalComponents/MarketMovers";
import Loading from '../Loading';

export default function Research() {
   
    return (
        <>
            {/* {!csvTest && <Loading />}
            {csvTest && <Percentile csvText={csvTest}/>} */}
            <MarketMovers />
        </>
    )
}