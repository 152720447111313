// const jwt = require('jsonwebtoken');
const jws = require('jws');


export function checkPermission(requiredPermissions, token) {
    if( typeof(requiredPermissions) === 'string') {
        requiredPermissions = [requiredPermissions];
    }
    // debugger;
    if(!token) return false;
    
    // const { payload } = jwt.decode(token);
    const { payload } = jws.decode(token);
    if( payload?.permissions ) {
        return requiredPermissions.every(p => payload.permissions.includes(p))
    }
    return false;
}
