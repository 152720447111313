import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'
import { Button, Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { MdDeleteForever } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { post, remove, patch } from '../../lib/api';

import { cancelEditAccount, toggleEditAccount } from './brokerAccountsSlice';
import { accountsSchema } from './accountsSchema';
import { BrokerAccountHeadView } from './functionalComponents';
import { initializeBrokerAccounts } from "./service";

const API_URL = process.env.REACT_APP_API_SERVER_URL;


export default function Subscriptions() {
    const { getAccessTokenSilently } = useAuth0();

    // Fetch resource on page load.
    useEffect( () => {
        initializeBrokerAccounts(getAccessTokenSilently);
      }, []);

    return (
        <>
            <Head />
            <EditableList />
            <CreateNew />
        </>
    );
}

function Head() {
    return (
        <h2>Accounts</h2>
    );
}

function EditableList() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const accounts = useSelector((state) => state.brokerAccounts.data);

    const handleRemoveAccount = useCallback(async (index) => {
        const accessToken = await getAccessTokenSilently();
        const formData = {
          "_id": accounts[index]['_id']['$oid'],
        };
        const responseData = await remove(API_URL, '/integrations/broker-account', formData, accessToken);

        initializeBrokerAccounts(getAccessTokenSilently, true);
    });

    const handleEditAccount = useCallback(async (formData, index) => {
        const accessToken = await getAccessTokenSilently();

        const patchBody = {
            _id: accounts[index]['_id']['$oid'],
            credentials: {...formData.credentials},
            broker: formData.broker,
        };
        const responseData = await patch(API_URL, '/integrations/broker-account', patchBody, accessToken);

        initializeBrokerAccounts(getAccessTokenSilently, true);
    });

    return (
        <>
            {accounts.length > 0 && accounts.map((account, index) =>
                <Card
                    className="my-2"
                    style={{
                        width: '100%'
                    }}
                    key={`account-${index}`}
                >
 
                    <CardHeader>
                        <Container>
                            <Row>
                                <Col>
                                    <BrokerAccountHeadView account={account} />
                                    <span className="float-end">
                                        <Button color="secondary" onClick={ () => dispatch(toggleEditAccount(index)) }> <FaEdit /> </Button>
                                        {' '}
                                        <Button color="danger" onClick={() => handleRemoveAccount(index)}> <MdDeleteForever /> </Button>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </CardHeader>
 
                    {account?._fe_isEditing && (
                        <CardBody>
                            <Form
                                schema={accountsSchema}
                                validator={validator}
                                formData={account}
                                onSubmit={({ formData }) => handleEditAccount(formData, index)}
                            >
                            <div className="mt-2">
                                <Button color="primary" type="submit">Save</Button>
                                {' '}
                                <Button onClick={() => dispatch(cancelEditAccount({account, index}))}>Cancel</Button>
                            </div>
                            </Form>

                        </CardBody>
                    )}
                </Card>
            )}
        </>
    );
}

function CreateNew() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [showForm, setShowForm] = useState(false);

    const handleSubmit = useCallback(async ({ formData }) => {
        const accessToken = await getAccessTokenSilently();
        const responseData = await post(API_URL, '/integrations/broker-account', formData, accessToken);

        // Hide form
        setShowForm(false);

        initializeBrokerAccounts(getAccessTokenSilently, true);
   });

    return (
        <>
            {showForm && (
                <Form 
                    schema={accountsSchema}
                    validator={validator}
                    onSubmit={handleSubmit}
                >
                    <div className="mt-2">
                        <Button color="primary" type="submit">Submit</Button>
                        {' '}
                        <Button color="secondary" outline onClick={ () => setShowForm(false) }>Cancel</Button>
                    </div>
                </Form>
            )}
            {!showForm && (
                <Button color="primary" onClick={ () => setShowForm(true) }>Add New</Button>
            )}
        </>
    );
}
