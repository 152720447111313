import { createSlice } from '@reduxjs/toolkit'

export const brokerAccountsSlice = createSlice({
  name: 'brokerAccounts',
  initialState: {
    value: 0,
    initialized: false,
    data: [],
  },
  reducers: {
    initialize: (state, action) => {
        state.initialized = true;
        state.data = action.payload;
        console.log('Initialized Broker Accounts: ', state.data);
    },
    insert: (state, action) => {
        state.data.push(action.payload);
    },
    remove: (state, action) => {
        console.log(action)
        state.data.splice(action.payload, 1);
    },
    update: (state, action) => {
      state.value += action.payload
    },
    cancelEditAccount: (state, action) => {
      const index = action.payload.index;
      state.data[index]['_fe_isEditing'] = false;
    },
    editAccount: (state, action) => {
      const index = action.payload;
      state.data[index]['_fe_isEditing'] = true;
    },
    toggleEditAccount: (state, action) => {
      const index = action.payload;
      state.data[index]['_fe_isEditing'] = !state.data[index]['_fe_isEditing'];
    },
  },
})

// Action creators are generated for each case reducer function
export const { initialize, remove, cancelEditAccount, editAccount, toggleEditAccount } = brokerAccountsSlice.actions

export default brokerAccountsSlice.reducer