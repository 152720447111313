export const subscriptionSchema = {
    title: 'Add Subscription',
    type: 'object',
    required: ['productType', 'scriptType', 'script', "batchInvestmentAmount", "perScriptAmountLimit" ],
    properties: {
        batchInvestmentAmount: {
            title: 'Batch Investment Amount',
            type: 'number',
            default: 2000
        },
        perScriptAmountLimit: {
            title: ' Per-script Limit Amount',
            type: 'number',
            default: 10000
        },
        productType: {
            title: 'Product Type',
            type: "string",
            enum: [
                "INVEST",
                "SWING",
                "INTRADAY"
            ],
            default: "SWING"
        },
        scriptType: {
            title: 'Script Type',
            type: "string",
            enum: [
                "INDIVIDUAL",
                "COMBINED",
            ],
            default: "COMBINED"
        },
    },
    allOf: [
        {
            if: {
                properties:  {
                    scriptType: {
                        const: "INDIVIDUAL"
                    }
                }
            },
            then: {
                properties: {
                    script: {
                        title: 'Script',
                        type: "string",
                        default: "RELIANCE",
                    }
                }
            },
        
        }, 
        {
            if: {
                properties:  {
                    scriptType: {
                        const: "COMBINED"
                    }
                }
            },
            then: {
                properties: {
                    script: {
                        title: 'Script',
                        type: "string",
                        enum: [
                            '', "LARGE_CAP", "MID_CAP", "SMALL_CAP"
                        ],
                        default: "MID_CAP"
                    }
                }
            }        
        },
    ]
}
