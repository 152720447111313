import { GET, get, patch } from '../../lib/api';

const API_URL = process.env.REACT_APP_API_SERVER_URL;


export async function get_percentile_data_csv(getAccessTokenSilently) {

    const accessToken = await getAccessTokenSilently();
    const response = await get(API_URL, '/research/percentile', accessToken);
    const url = response?.percentile_data_csv_url;

    const responseText = await GET(url, { isJson: false });
    return responseText;
}

export async function get_market_movers(getAccessTokenSilently) {

    const accessToken = await getAccessTokenSilently();
    const response = await get(API_URL, '/research/market_movers', accessToken);
    const data = response?.market_movers;

    return data;
}

export default { get_percentile_data_csv, get_market_movers }
