import React from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import AuthenticationGuard from "./components/AuthenticationGuard";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import Integrations from "./views/Integrations";
import Subscriptions from "./views/Subscriptions";
import Research from "./views/Research";
import Callback from "./views/Callback";
import SignUp from "./views/SignUp";
import NotFoundPage from "./views/NotFoundPage";
import { useAuth0 } from "@auth0/auth0-react";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<AuthenticationGuard component={Profile} />} />
            <Route path="/external-api" element={<AuthenticationGuard component={ExternalApi} />} />
            <Route path="/integrations" element={<AuthenticationGuard component={Integrations} />} />
            <Route path="/subscriptions" element={<AuthenticationGuard component={Subscriptions} />} />
            <Route path="/research" element={<AuthenticationGuard component={Research} />} />
            <Route path="/callback" element={<AuthenticationGuard component={Callback} />} />
            <Route path="/signup" element={<SignUp component={SignUp} />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Container>
        <Footer />
      </div>
  );
};

export default App;
