import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { post } from '../lib/api';

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const API_URL = process.env.REACT_APP_API_SERVER_URL;


const SignUp = () => {
    const { user, getAccessTokenSilently, isLoading } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        (async function() {
            if(isLoading || !user) {
                return;
            }

            const accessToken = await getAccessTokenSilently();
            console.log(user);
            const body = { nickname: user.nickname, email: user.email, sub: user.sub } = user;
            await post(API_URL, '/signup', body, accessToken);
            navigate('/');
        })();
    }, [user]);

    return (
        <>
            {!isLoading && user && <Container className="mb-5">
                <Row className="align-items-center profile-header mb-5 text-center text-md-left">
                    <Col md={2}>
                        <img
                        src={user.picture}
                        alt="Profile"
                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                        />
                    </Col>
                    <Col md>
                        <h2>{user.name}</h2>
                        <p className="lead text-muted">{user.email}</p>
                    </Col>
                </Row>
                <Row>
                    <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
                </Row>
            </Container>}
        </>
    );
}

export default SignUp;
