import React from "react";

import Research from '../components/research/Research';

export default function ResearchComponent() {

  return (
    <Research />
  );
};
