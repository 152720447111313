import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    {/* <div className="logo" />
    <p>
      Sample project provided by <a href="https://auth0.com">Auth0</a>
    </p> */}
    <div>Contact us <a href="mailto:contact@investingwise.in">contact@investingwise.in</a></div>
  </footer>
);

export default Footer;
