import React from "react";

import Subscriptions from '../components/subscriptions/Subscriptions';

export default function SubscriptionsComponent() {

  return (
    <Subscriptions />
  );
};
