import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

// import logo from "../assets/logo.svg";

const Hero = () => (
  <>
  {/* // <div className="text-center hero my-5">
  //   <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
  //   <h1 className="mb-4">React.js Sample Project</h1>

  //   <p className="lead">
  //     This is a sample application that demonstrates an authentication flow for
  //     an SPA, using <a href="https://reactjs.org">React.js</a>
  //   </p>
  // </div> */}

    <Banner />
  </>
);

export default Hero;


const items = [
  {
    src: '/get-rich-slowly.png',
    altText: 'Are you ready, to get rich slowly?',
    // caption: 'Are you ready?',
    key: 2,
  },
  {
    src: '/let-money-work.jpeg',
    altText: 'Employ money to work',
    header: 'Employ money to work',
    key: 1,
  },
];

function Banner(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          className='text-danger'
          captionText={item.caption}
          captionHeader={item.header}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}
