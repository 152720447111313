import { useEffect, useState } from "react";
import researchService from '../service';
import { useAuth0 } from '@auth0/auth0-react';
import Percentile from "./Percentile";
import Loading from '../../Loading';
import { Container, Col, Row, Table } from "reactstrap";

export default function MarketMovers() {
    const [marketMovers, setMarketMovers] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        (async function() {
            const data = await researchService.get_market_movers(getAccessTokenSilently);
            setMarketMovers(data || []);
        })();
    }, []);

    return (
        <>
            {!marketMovers && <Loading />}
            {marketMovers && (
                <>
                    <Container>
                        <Row id="top-gainers">
                            <Col>
                                <Row>last_3_day_top_gainers</Row>
                                <Row> {marketMoversTable(marketMovers['last_3_day_top_gainers'])} </Row>
                            </Col>
                            <Col>
                                <Row>last_5_day_top_gainers</Row>
                                <Row> {marketMoversTable(marketMovers['last_5_day_top_gainers'])} </Row>
                            </Col>
                            <Col>
                                <Row>last_9_day_top_gainers</Row>
                                <Row> {marketMoversTable(marketMovers['last_9_day_top_gainers'])} </Row>
                            </Col>
                            <Col>
                                <Row>last_15_day_top_gainers</Row>
                                <Row> {marketMoversTable(marketMovers['last_15_day_top_gainers'])} </Row>
                            </Col>
                        </Row>
                        <Row id="top-loosers">
                            <Col>
                                <Row>last_3_day_top_loosers</Row>
                                <Row> {marketMoversTable(marketMovers['last_3_day_top_loosers'], 'table-danger')} </Row>
                            </Col>
                            <Col>
                                <Row>last_5_day_top_loosers</Row>
                                <Row> {marketMoversTable(marketMovers['last_5_day_top_loosers'], 'table-danger')} </Row>
                            </Col>
                            <Col>
                                <Row>last_9_day_top_loosers</Row>
                                <Row> {marketMoversTable(marketMovers['last_9_day_top_loosers'], 'table-danger')} </Row>
                            </Col>
                            <Col>
                                <Row>last_15_day_top_loosers</Row>
                                <Row> {marketMoversTable(marketMovers['last_15_day_top_loosers'], 'table-danger')} </Row>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </>
    )
}


function marketMoversTable(data = [], theme='table-primary') {
    return (
        <Table>
            <thead>
                <tr>
                <th>
                    Symbol
                </th>
                <th>
                    Change %
                </th>
                </tr>
            </thead>

            <tbody>
                {data.map(([symbol, change]) => {
                        return (
                            <tr className={theme}>
                                <td>
                                    {symbol}
                                </td>
                                <td>
                                    {change}
                                </td>
                            </tr>
                        )
                    }
                )}
            </tbody>
        </Table>
    );
}