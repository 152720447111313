import { createSlice } from '@reduxjs/toolkit'

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    initialized: false,
    data: [],
  },
  reducers: {
    initialize: (state, action) => {
        state.initialized = true;
        state.data = action.payload;
        console.log('Initialized Subscriptions: ', state.data);
    },
    remove: (state, action) => {
        state.data.splice(action.payload, 1);
    },
    linkAccount: (state, action) => {
      console.log('linkAccount action: ', action);
      const index = action.payload;
      state.data[index]['_fe_linkAccount'] = true;
    },
    toggleLinkAccount: (state, action) => {
      const index = action.payload;
      state.data[index]['_fe_linkAccount'] = !state.data[index]['_fe_linkAccount'];
    },
  },
})

// Action creators are generated for each case reducer function
export const { initialize, remove, linkAccount, toggleLinkAccount } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer