const contentData = [
  {
    title: "Integrate broker accounts",
    link: "/integrations",
    description:
      "Integrate your broker accounts on IW for automated and hassle free trading actions in your account."
  },
  {
    title: "Subscribe an investment plan",
    link: "/subscriptions",
    description:
      "IW provides multiple plans for Investing and Trading, explore and choose a plan based on your investing goals and risk-appetite."
  },
  {
    title: "Link subscriptions to accounts",
    link: "/integrations",
    description:
      "Link your account to subscriptions and configure capital allocation based on your comfort level."
  },
  {
    title: "Analyse returns",
    link: "/analyse",
    description:
      "Track returns and get insights across all accounts and plans."
  }
];

export default contentData;
