export const accountsSchema = {
    title: 'Add account details',
    type: 'object',
    required: ['broker', 'credentials'],
    properties: {
        broker: {
            title: 'Broker',
            type: "string",
            enum: [
                "FYERS",
                "DHAN",
                "BINANCE"
            ],
            default: "FYERS",
        },
    },
    allOf: [
        {
            if: {
                properties: {
                    broker: {
                        const: "FYERS"
                    }
                }
            },
            then: {
                properties: {
                    credentials: {
                        title: 'Credentials',
                        type: "object",
                        properties: {
                            fyersId: {
                                title: 'Fyers Id',
                                type: "string"
                            },
                            appId: {
                                title: 'App Id',
                                type: "string"
                            },
                            secretId: {
                                title: 'Secret Id',
                                type: "string"
                            },
                            pin: {
                                title: 'Pin',
                                type: "string"
                            },
                            totpKey: {
                                title: 'tOTP Key',
                                type: "string"
                            },
                            redirectUri: {
                                title: 'Redirect URI',
                                type: "string",
                                default: "http://amaranthine2.jeffwho.co.in/integrations"
                            },
                        },
                        required: [
                            "fyersId",
                            "appId",
                            "secretId",
                            "pin",
                            "totpKey",
                            "redirectUri"
                        ]
                    }
                },
            },
        },
        {
            if: {
                properties: {
                    broker: {
                        const: "DHAN"
                    }
                }
            },
            then: {
                properties: {
                    credentials: {
                        title: 'Credentials',
                        type: "object",
                        properties: {
                            clientId: {
                                title: 'Client Id',
                                type: "string"
                            },
                            accessToken: {
                                title: 'Access Token',
                                type: "string"
                            },
                        },
                        required: [
                            "clientId",
                            "accessToken",
                        ]
                    }
                },
            },
        },
        {
            if: {
                properties: {
                    broker: {
                        const: "BINANCE"
                    }
                }
            },
            then: {
                properties: {
                    credentials: {
                        title: 'Credentials',
                        type: "object",
                        properties: {
                            apiKey: {
                                title: 'Api Key',
                                type: "string"
                            },
                            secretKey: {
                                title: 'Secret Key',
                                type: "string"
                            },
                        },
                        required: [
                            "apiKey",
                            "secretKey"
                        ]
                    }
                },
            },
        }
    ]
}