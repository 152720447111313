import { Badge } from "reactstrap";

export function BrokerAccountHeadView({ account }) {
    return (
        <>
            {account?.broker === "FYERS" && (
                <>
                    <Badge color="primary">{account?.broker}</Badge> {' '} {account?.credentials?.fyersId}
                </>
            )}
            {account?.broker === "DHAN" && (
                <>
                    <Badge color="success">{account?.broker}</Badge> {' '} {account?.credentials?.clientId}
                </>
            )}
            {account?.broker === "BINANCE" && (
                <>
                    <Badge color="secondary">{account?.broker}</Badge> {' '} {account?.credentials?.apiKey}
                </>
            )}
        </>
    )
}