import store from '../../store';
import { initialize } from './brokerAccountsSlice';
import { post, get, remove, patch } from '../../lib/api';

const API_URL = process.env.REACT_APP_API_SERVER_URL;


// Initializes store if not already done 
// by fetching data from API. 
export async function initializeBrokerAccounts(getAccessTokenSilently, forceInit = false) {

    if( store.getState().brokerAccounts?.initialized && !forceInit ) {
        return;
    }

    const accessToken = await getAccessTokenSilently();
    const response = await get(API_URL, '/integrations/broker-account', accessToken);
    return store.dispatch(initialize(response?.accounts || []));
}

// Get Account by id from redux-store
export function getAccountById(accounts, id) {
    // await initializeBrokerAccounts(getAccessTokenSilently);
    return accounts.find(account => account['_id']['$oid'] === id);
}
