import store from '../../store';
import { initialize } from './subscriptionsSlice';
import { initialize as initializeBrokerAccounts } from '../brokerAccounts/service';
import { get, patch } from '../../lib/api';

const API_URL = process.env.REACT_APP_API_SERVER_URL;


// Initializes store if not already done 
// by fetching data from API. 
export async function initializeSubscriptions(getAccessTokenSilently, forceInit = false) {

    if( store.getState().subscriptions?.initialized && !forceInit ) {
        return;
    }

    const accessToken = await getAccessTokenSilently();
    const response = await get(API_URL, '/subscriptions', accessToken);
    return store.dispatch(initialize(response?.subscriptions || []));
}

// Links a Subscription to an Account.
export async function linkAccount(getAccessTokenSilently, subscription, account, batchInvestmentAmount, perScriptAmountLimit) {

    const accessToken = await getAccessTokenSilently();
    const body = {
        _id: subscription['_id']['$oid'],
        brokerAccountId: account === null ? '' : account['_id']['$oid'],
        batchInvestmentAmount: parseInt(batchInvestmentAmount),
        perScriptAmountLimit: parseInt(perScriptAmountLimit),
    };
    return await patch(API_URL, '/subscriptions', body, accessToken);
}

// Toggles Subscription.
export async function toggleSubbscription(getAccessTokenSilently, subscription) {
    const accessToken = await getAccessTokenSilently();
    const body = {
        _id: subscription['_id']['$oid'],
        active: !subscription?.active,
    };
    return patch(API_URL, '/subscriptions', body, accessToken);
}

export default { initializeSubscriptions, linkAccount, toggleSubbscription }
